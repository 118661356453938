/*=========================================================================================
  File Name: moduleHospitalActions.js
  Description: Hospital Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
    AddHospital({ commit }, item) {
        debugger
        return new Promise((resolve, reject) => {
            axios.post("api/Hospital/AddHospital", item)
                .then((response) => {
                    commit('ADD_ITEM', Object.assign(item, { ID: response.data.Data.ID }))
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    AddHospitalUserReview(context, model) {
        return new Promise((resolve, reject) => {
            axios
                .post("api/HospitalUserReview/AddHospitalUserReview", model)
                .then(response => {
                    resolve(response);
                })
                .catch(error => {
                    reject(error);
                    window.showError(err.response.data.message);
                });
        });
    },
    GetAllHospitals({ commit }) {
        debugger
        return new Promise((resolve, reject) => {
            axios.get("api/Hospital/GetAllHospitals")
                .then((response) => {
                    debugger
                    commit('SET_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    
    SearchHospitals({ commit }, search) {
        debugger;
        return new Promise((resolve, reject) => {
            axios.post("api/Hospital/SearchHospitals", search)
                .then((response) => {
                    commit('SET_SearchHospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SimpleSearchHospitals({ commit }, search) {
        debugger;
        return new Promise((resolve, reject) => {
            axios.post("api/Hospital/SimpleSearchHospitals", search)
                .then((response) => {
                    commit('SET_SimpleSearchHospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    UpdateHospitalSurgery({ commit }, hospitalSurgery) {
        return new Promise((resolve, reject) => {
            axios.post("api/HospitalSurgery/UpdateHospitalSurgery", hospitalSurgery)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    ConfirmPatientReservationSurgeryDate({ commit }, reservationSurgery) {
        return new Promise((resolve, reject) => {
            axios.post("api/PatientReservationSurgery/ConfirmPatientReservationSurgeryDate", reservationSurgery)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SetDoctorPatientReservationSurgery({ commit }, reservationSurgery) {
        return new Promise((resolve, reject) => {
            axios.post("api/PatientReservationSurgery/SetDoctorPatientReservationSurgery", reservationSurgery)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    ChangePatientReservationSurgeryPice({ commit }, reservationSurgery) {
        return new Promise((resolve, reject) => {
            axios.post("api/PatientReservationSurgery/ChangePatientReservationSurgeryPice", reservationSurgery)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    UpdatePatientReservationSurgery(context, reservationSurgery) {
        return new Promise((resolve, reject) => {
            axios.post("api/PatientReservationSurgery/UpdatePatientReservationSurgery", reservationSurgery)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    ChangePatientReservationSurgeryStatus({ commit }, reservationSurgery) {
        return new Promise((resolve, reject) => {
            axios.post("api/PatientReservationSurgery/ChangePatientReservationSurgeryStatus", reservationSurgery)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    AddHospitalSurgery({ commit }, hospitalSurgery) {
        return new Promise((resolve, reject) => {
            axios.post("api/HospitalSurgery/AddHospitalSurgery", hospitalSurgery)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SearchHospitalSurgies({ commit }, search) {
        return new Promise((resolve, reject) => {
            axios.post("api/HospitalSurgery/SearchHospitalSurgerys", search)
                .then((response) => {
                    commit('SET_SearchHospitalSurgeries', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SaveHospitalSurgeries(context, hospitalSurgeries) {
        return new Promise((resolve, reject) => {
            axios.post("api/HospitalSurgery/SaveHospitalSurgeries", hospitalSurgeries)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetHospitalSurgeryDoctors({ commit }, HospitalSurgeryID) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSurgeryDoctor/GetHospitalSurgeryDoctors?HospitalSurgeryID=" + HospitalSurgeryID)
                .then((response) => {

                    commit('SET_SearchHospitalDoctors', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetSurgeryDoctors({ commit }, HospitalSurgeryID) {
        debugger
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSurgeryDoctor/GetSurgeryDoctors?HospitalSurgeryID=" + HospitalSurgeryID)
                .then((response) => {

                    commit('SET_Doctors', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SearchHospitalDoctors({ commit }, search) {
        debugger  
        return new Promise((resolve, reject) => {
            axios.post("api/HospitalSpecialtyDoctor/SearchHospitalSpecialtyDoctors", search)
                .then((response) => {
                    commit('SET_SearchHospitalDoctors', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    SearchHospitalSpecialtys({ commit }, search) {
        return new Promise((resolve, reject) => {
            axios.post("api/HospitalSpecialty/SearchHospitalSpecialtys", search)
                .then((response) => {
                    commit('SET_HospitalSpecialties', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    UpdateHospital(context, item) {
        return new Promise((resolve, reject) => {
            debugger
            axios.post("api/Hospital/UpdateHospital", item)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetHospitalDoctors(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSpecialtyDoctor/GetHospitalDoctors?HospitalID=" + itemid)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetHospitalSpcialtyDoctors(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSpecialtyDoctor/GetHospitalSpcialtyDoctors?HospitalID=" + itemid)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    // GetHospitalSurgeryDoctors(context, itemid) {
    //   return new Promise((resolve, reject) => {
    //     debugger
    //     axios.get("api/HospitalSpecialtyDoctor/GetHospitalDoctors?HospitalID=" + itemid)
    //       .then((response) => {
    //         // commit('UPDATE_Hospital', response.data.Data)
    //         resolve(response)
    //       })
    //       .catch((error) => { reject(error) })
    //   })
    // },
    SaveHospitalSugeryDoctors(context, item) {
        return new Promise((resolve, reject) => {
            debugger
            axios.post("api/HospitalSurgeryDoctor/SaveHospitalSugeryDoctors", item)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SaveHospitalDoctors(context, item) {
        return new Promise((resolve, reject) => {
            debugger
            axios.post("api/HospitalSpecialtyDoctor/SaveHospitalDoctors", item)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    DeleteOldHospitalDoctors(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSpecialtyDoctor/DeleteOldHospitalDoctors?hospitalID=" + itemid)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteSurgeryIncludeItem(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSurgery/DeleteSurgeryIncludeItem?ID=" + itemid)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteSurgeryExcludeItem(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSurgery/DeleteSurgeryExcludeItem?ID=" + itemid)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    DeleteSurgeryDocumentItem(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSurgery/DeleteSurgeryDocumentItem?ID=" + itemid)
                .then((response) => {
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    DeleteOldHospitalSurgeryDoctors(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSurgeryDoctor/DeleteOldHospitalSurgeryDoctors?HospitalSurgeryID=" + itemid)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SaveParentSpecialities(context, item) {
        return new Promise((resolve, reject) => {
            debugger
            axios.post("api/HospitalSpecialty/SaveParentSpecialities", item)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    SaveChiledSpecialities(context, item) {
        return new Promise((resolve, reject) => {
            debugger
            axios.post("api/HospitalSpecialty/SaveChiledSpecialities", item)
                .then((response) => {

                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetHospital(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/Hospital/GetHospitalByID?HospitalID=" + itemid)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

    DeleteOldParentSpecialities(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSpecialty/DeleteOldParentSpecialities?hospitalID=" + itemid)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    DeleteAttachmenByID(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/Hospital/DeleteAttachmenByID?HospitalattachmentID=" + itemid)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    DeleteOldChiledSpecialities(context, data) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSpecialty/DeleteOldChiledSpecialities?hospitalID=" + data.hospitalID + "&specialityID=" + data.specialityID)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetHospitalParentSpecialty(context, itemid) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSpecialty/GetHospitalParentSpecialty?HospitalID=" + itemid)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    GetHospitalChiledSpecialty(context, data) {
        return new Promise((resolve, reject) => {
            debugger
            axios.get("api/HospitalSpecialty/GetHospitalChiledSpecialty?HospitalID=" + data.HospitalID + "&SpecialityID=" + data.SpecialityID)
                .then((response) => {
                    // commit('UPDATE_Hospital', response.data.Data)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },
    DeleteHospital({ commit }, item) {
        return new Promise((resolve, reject) => {
            axios.get("api/Hospital/DeleteHospital?HospitalID=" + item.ID)
                .then((response) => {
                    commit('REMOVE_ITEM', item.ID)
                    resolve(response)
                })
                .catch((error) => { reject(error) })
        })
    },

}
<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div>
    <div class="vx-row">
  <feather-icon v-if="EnglishLan" @click="back()" icon="ChevronLeftIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <feather-icon v-else @click="back()" icon="ChevronRightIcon" style="width: 30px;cursor: pointer;">
      </feather-icon>
      <h2 style="color: #0082d5" class="mb-2">
        {{ $t("AddHospitalSpecialities") }}
      </h2>
    </div>
    <div class="vx-row ml-5">
      <h2 class="">{{ $t("AddSpeciality") }}</h2>
    </div>

    <div class="vx-row">
     
      <div class="vx-col lg:w-1/5 w-full w-full mt-5"></div>
      <div class="vx-col lg:w-1/3 w-full w-full mt-5">
      <span class="warning">  {{$t("saveNote")}}</span>
        <vs-card class="vx-col mt-1" style="height: 50vh; overflow-y: scroll">
          <vs-input
            v-model="search.SpecialityName"
            :placeholder="$t('Search')"
            class="w-full"
            name="search"
          />
          <div
            v-for="item in filterSpeciality"
            :key="item.ID"
            :class="item.IsSelected ? 'Active' : 'InActive'"
            class="shadow-md w-full mt-2"
          >
            <p class="m-5 p-3 vx-row">
              <vs-checkbox
                v-model="item.IsSelected"
                @input="SpecialityChecked(item)"
                :disabled="(item.CantChecked==true)"
              ></vs-checkbox>
              {{ item.Name }}
            </p>
          </div>
        </vs-card>
      </div>

      <div class="vx-col lg:w-1/3 w-full mt-5">
        <vs-card class="vx-col mt-8" style="height: 50vh; overflow-y: scroll">
          <h2 class="m-1 Active">{{ $t("Specialities") }}</h2>

          <div
            v-for="item in HospitalParentSpecialties"
            :key="item.ID"
            class="InActive shadow-md w-full mt-2"
             v-if="item.IsActive!=false"
          >
            <!-- <p class="m-5 p-3 vx-row"  >                           -->
            <li style="margin: 5%; padding: 3%; font-size: medium">
              {{ item.Specialty.Name }}
            </li>
          </div>
        </vs-card>
        
        <div class="vx-row m-5">
          <div class="vx-col lg:w-1/3  mt-3" style="color: #004477">
            <u @click="SaveSpecialities()" style="font-size: 20px">{{
              $t("Save")
            }}</u>
          </div>
          <div class="vx-col lg:w-2/3">
            <vs-button
              color="#004477"
              @click="AddSubSpecialities()"
              class="vx-row mt-3 customizer-btn text-sm text-white"
              ><h6 style="color: white">{{ $t("AddSubSpecialities") }}</h6>
            </vs-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import LoginFirebase from "./LoginFirebase.vue"
//import LoginAuth0 from "./LoginAuth0.vue"
import axios from "@/axios.js";
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleHospital from "@/store/hospital/moduleHospital.js";

import { domain, mainSetting } from "@/gloabelConstant.js";
export default {
  data() {
    return {
      EnglishLan:false,
      baseURL: domain,
      UploadRequestHeader: mainSetting.UploadRequestHeader,
      uploadUrl: domain + "api/Common/UploadFile",
      Model: {},
      search: {},
      HospitalParentSpecialties: [],
    };
  },
  computed: {
    parentSpecialties() {
      return this.$store.state.SpecialtyList.parentSpecialties;
    },
    childSpecialties() {
      return this.$store.state.SpecialtyList.childSpecialties;
    },
    filterSpeciality() {
      if (this.search.SpecialityName) {
        var vm = this;
        return this.parentSpecialties.filter(function (obj) {
          return (
            obj.Name.toLowerCase().indexOf(
              vm.search.SpecialityName.toLowerCase()
            ) > -1
          );
        });
      } else return this.parentSpecialties;
    },
  },
  methods: {
    AddSubSpecialities() {
      this.$router.push({ name: "AddHospitalSubSpecialites" });
    },
    back() {
      this.$router.go(-1);
    },
    SpecialityChecked(item) {
      debugger;
      if (item.IsSelected == true) {
        this.AddHospitalSpeciality(item);
      } else {
        this.DeleteHospitalSpeciality(item);
      }
    },
    DeleteHospitalSpeciality(item) {
      var index = this.HospitalParentSpecialties.findIndex(
        (x) => x.SpecialtyID == item.ID
      );
      this.HospitalParentSpecialties.splice(index, 1);
    },
    AddHospitalSpeciality(item) {
      var index = this.HospitalParentSpecialties.findIndex(
        (x) => x.SpecialtyID == item.ID
      );
      if (index >= 0) {
        //alert
      } else {
        var hospitalParent = {};
        hospitalParent.Specialty = {};
        hospitalParent.Specialty.Name = item.Name;
        hospitalParent.Specialty.SpecialtyID = item.ID;
        hospitalParent.Specialty.HospitalID =
          this.$store.state.AppActiveUser.Hospital.ID;
        hospitalParent.SpecialtyID = item.ID;
        hospitalParent.IsActive =  true;
        hospitalParent.HospitalID = this.$store.state.AppActiveUser.Hospital.ID;
        this.HospitalParentSpecialties.push(hospitalParent);
      }
    },
    SaveSpecialities() {
      this.$vs.loading();
      if (this.HospitalParentSpecialties.length > 0) {
        this.$store
          .dispatch(
            "HospitalList/SaveParentSpecialities",
            this.HospitalParentSpecialties
          )
          .then((res) => {
            if (res.status == 200) {
              this.$vs.loading.close();
              window.showSuccess();
            }
            this.$vs.loading.close();
          });
      } else {
        this.$store
          .dispatch(
            "HospitalList/DeleteOldParentSpecialities",
            this.$store.state.AppActiveUser.Hospital.ID
          )
          .then((res) => {
            if (res.status == 200) {
              this.$vs.loading.close();
              window.showSuccess();
            }
            this.$vs.loading.close();
          });
      }
    },
    CheckSelectedSpecialty()
    {
       this.HospitalParentSpecialties.forEach((element) => {
          debugger;
          var index = this.parentSpecialties.findIndex(
            (x) => x.ID == element.SpecialtyID
          );
          this.parentSpecialties[index].IsSelected = true;

            var index2 = this.parentSpecialties.findIndex(
              (x) => (x.ID == element.SpecialtyID &&element.IsActive==false)
            );
            if (index2 != -1) {
              this.parentSpecialties[index2].CantChecked = true;
            }
        });
    }
  },
  created() {
        this.EnglishLan=localStorage.getItem("SaveLang")=="en"||localStorage.getItem("SaveLang")==null?true:false;

    if (!moduleSpecialty.isRegistered) {
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleHospital.isRegistered) {
      this.$store.registerModule("HospitalList", moduleHospital);
      moduleHospital.isRegistered = true;
    }

    this.$store.dispatch("SpecialtyList/GetParentSpecialty").then(res=>{
      this.CheckSelectedSpecialty();
    });
   
    this.$store
      .dispatch(
        "HospitalList/GetHospitalParentSpecialty",
        this.$store.state.AppActiveUser.Hospital.ID
      )
      .then((res) => {
        this.HospitalParentSpecialties = res.data.Data;
        this.$vs.loading.close();

         this.CheckSelectedSpecialty();

      });
  },
};
</script>

<style >
.Active {
  color: #004477;
  padding: 0px;
  border-radius: 12px;
}
.InActive {
  padding: 0px;
  border-radius: 12px;
}
p {
  font-size: medium;
}
</style>
